import i18next from 'i18next';

i18next.addResources('es', 'DetailModalDate', {
  dateTitle: 'Fecha',
  timeTitle: 'Hora',
});

i18next.addResources('es', 'DetailModalStatus', {
  statusTitle: 'Estado',
  confirmed: 'Confirmado',
  reserved: 'Reservado',
  cancelled: 'Cancelado',
  finish: 'Enviado',
  review: 'Revisar',
});
