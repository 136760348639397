import { FC } from 'react';
import styled from 'styled-components';
import { BlueButton } from '../Button/Button';
import { ModalProps } from './types';
import colors from '../../../constants/colors';

const Modal: FC<ModalProps> = ({
  className,
  onClose,
  icon,
  title,
  description,
  label,
}) => (
  <div className={className}>
    <div className="content">
      <img src={icon} alt="ICON" />
      <p>{title}</p>
      <p>{description}</p>
      <div className="button">
        <BlueButton onClick={onClose}>{label}</BlueButton>
      </div>
    </div>
  </div>
);

const BaseModal = styled(Modal)`
  align-items: center;
  backdrop-filter: blur(2px);
  background-color: #00000078;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 2;
  .content {
    background-color: ${colors.white};
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: min(90%, 650px);
    img {
      height: 88px;
    }
    p {
      font-size: 1.25rem;
      text-align: center;
    }
    p:nth-child(2) {
      color: ${colors.blue};
      font-weight: 700;
      margin-bottom: 0px;
    }
    p:nth-child(3) {
      margin-bottom: 48px;
      margin-top: 0px;
    }
    .button {
      margin: 0px auto;
    }
  }
`;

export default BaseModal;
