import i18next from 'i18next';

i18next.addResources('es', 'Calendar', {
  title: 'Gestión de turnos',
  today: 'Hoy',
  cardTitle: 'Bautismo',
  confirmLabel: 'Confirmados: {{value}}',
  actionTitle: 'Datos del bautismo',
  sended: 'Enviado',
  reviewed: 'Revisar',
});
