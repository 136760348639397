import { createContext, FC, useContext, useReducer } from 'react';

const initialValues = {};

const globalStateContext = createContext(initialValues);
const dispatchStateContext = createContext(null);

export const GlobalStateProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    (actualState: typeof initialValues, newValue: any) => ({
      ...actualState,
      ...newValue,
    }),
    initialValues,
  );
  return (
    <globalStateContext.Provider value={state}>
      <dispatchStateContext.Provider value={dispatch}>
        {children}
      </dispatchStateContext.Provider>
    </globalStateContext.Provider>
  );
};

export const useGlobalState = (): [typeof initialValues, any] => [
  useContext(globalStateContext),
  useContext(dispatchStateContext),
];
