import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './app/App';
import { GlobalStateProvider } from './config/store';

Sentry.init({
  dsn: 'https://e3f3c34750f049f4a71b6245e58bf03b@o542400.ingest.sentry.io/5904064',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>Ha ocurrido un error</p>}>
    <GlobalStateProvider>
      <App />
    </GlobalStateProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('app'),
);
