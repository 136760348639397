import { FC, useCallback, useEffect, useState } from 'react';
import BaseModal from '../Modal/Modal';
import checkIcon from '../../assets/check.svg';

interface Messages {
  title: string;
  description: string;
  label: string;
}

export const showSuccess = (data: Messages) => {
  const errorEvent = new CustomEvent('success-message', { detail: data });
  document.dispatchEvent(errorEvent);
};

const SuccessMessage: FC = () => {
  const [messages, setMessages] = useState<Messages>(null);
  const onClose = useCallback(() => {
    setMessages(null);
  }, []);
  useEffect(() => {
    document.addEventListener('success-message', (event: CustomEvent) => {
      setMessages(event.detail);
    });
  }, []);

  return (
    <BaseModal
      icon={checkIcon}
      title={messages?.title}
      description={messages?.description}
      label={messages?.label}
      isOpen={messages !== null}
      onClose={onClose}
    />
  );
};

export default SuccessMessage;
