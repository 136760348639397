export default {
  blue: '#2F80ED',
  darkBlue: '#002E5A',
  darkerBlue: '#03509A',
  white: '#FFFFFF',
  border: '#E5E5E5;',
  background: '#FAFAFA',
  inputBorder: '#DADADA',
  placeHolder: '#7A7A7A',
  dayColor: '#4F4F4F',
  grey: '#CCC',
  darkGrey: '#CECECE',
  lightGrey: '#787878',
  red: '#EE0202',
  dayCard: '#D7E5F8',
  yellow: '#FAFF00',
  lightBlue: 'rgba(47, 128, 237, 0.17)',
  lighterBlue: 'rgba(47, 128, 237, 0.06)',
  lighterGreen: '#CEFFCD',
  green: '#A8FFA0',
  successGreen: '#1E8E3E',
  reviewYellow: '#FFE600',
  strokeReviewYellow: '#E4A60E',
  strokeGreenDarker: '#14662B',
  lighterRed: '#FCE8E7',
  lightRed: '#F9C8C8',
};
