import { FC, lazy, Suspense } from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import '../config/api';
import routes from '../constants/routes';
import ConnectionError from './components/ConnectionError/ConnectionError';
import SuccessMessage from './components/SuccesMessages/SuccessMessages';

const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: #F5F5F5;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
`;

const LazyCalendar = lazy(() => import('./pages/Calendar/Calendar'));
const LazyLogin = lazy(() => import('./pages/Login/Login'));

const App: FC = () => (
  <Router>
    <GlobalStyles />
    <ConnectionError />
    <SuccessMessage />
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path={routes.CALENDAR}>
          <LazyCalendar />
        </Route>
        <Route exact path={routes.LOGIN}>
          <LazyLogin />
        </Route>
        <Redirect from="*" to={routes.LOGIN} />
      </Switch>
    </Suspense>
  </Router>
);

export default App;
